.App {
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	font-size: larger;
}

#root {
	height: 100%;
	width: 100%;
}

div {
	white-space: pre-line;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.carla-box {
	position: absolute;
	top: 100%;
	right: 100%;
}

.carla-box > img {
	height: 200px;
	width: 200px;
}

.left-point {
	width: 0;
	height: 0;
	border-left: 2vh solid transparent;
	border-right: 2vh solid transparent;
	border-top: 10vh solid gray;
	position: absolute;
	top: calc(100% - 4.5vh);
	left: 10%;
	transform: rotate(60deg);
}

.mic-change {
	margin: 12px;
	border-radius: 50%;
	height: 32px;
	width: 32px;
	cursor: pointer;
}
