.initializer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.dashboard-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: center;
	justify-content: center;
	padding: 24px;
	border-radius: 16px;
	box-sizing: border-box;
	overflow: hidden;
}

.dashboard {
	box-sizing: border-box;
	padding: 16px;
	height: max-content;
	box-sizing: border-box;
	background: gray;
	color: white;
	position: relative;
}

.dashboard-bubble-body {
	background-color: gray;
	max-width: 60vw;
	min-height: 150px;
	padding: 32px;
	display: grid;
	place-items: center;
	height: max-content;
}

.dashboard-bubble-body > div {
	display: grid;
	place-items: center;
}

.question-body-text {
	font-size: 32px;
	padding: 12px;
	box-sizing: border-box;
}

.question-body-image-container {
	display: grid;
	grid-template-columns: repeat(4, auto);
	justify-content: center;
	flex-wrap: wrap;
	max-width: 60vw;
	gap: 12px;
	/* max-height: 300px; */
}

.question-body-image {
	max-height: 150px;
	border-radius: 16px;
	overflow: hidden;
	max-width: 150px;
	min-width: 150px;
	min-height: 150px;
	background: lightgray;
	cursor: pointer;
}

.dashboard-session-btn {
	display: flex;
	justify-content: center;
	margin: 10px;
}

.dashboard-header {
	height: 90px;
	background: gray;
	color: white;
	display: flex;
	justify-content: left;
	align-items: center;
	padding: 0px 35px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}

.dashboard-display-name {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	height: 100%;
}

.dashboard-display-name > img {
	height: 50px;
	width: 50px;
}

.dashboard-page-container {
	height: 100%;
	width: 100%;
	padding: 24px;
	box-sizing: border-box;
	display: grid;
	place-items: center;
	overflow: hidden;
}

.dashboard-screen {
	background: gray !important;
	color: white !important;
	position: relative;
	padding: 32px;
	max-width: 60vw !important;
	display: grid;
	place-items: center;
}

@media screen and (max-width: 1280px) {
	.question-body-image {
		max-width: 135px;
		max-height: 135px;
		min-height: 90px;
		min-width: 90px;
	}
}

@media screen and (max-width: 960px) {
	.question-body-image {
		max-width: 85px;
		max-height: 85px;
		min-height: 60px;
		min-width: 60px;
	}
	.question-body-image-container {
		max-width: 400px;
		gap: 5px;
	}
}

@media screen and (max-width: 600px) {
	.question-body-image {
		max-width: 70px;
		max-height: 70px;
		min-height: 60px;
		min-width: 60px;
	}
	.question-body-image-container {
		max-width: 400px;
		gap: 5px;
	}
}
