#background-stars {
	position: fixed;
	right: 0;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
}

.room-counter {
	font-size: 64px;
	display: flex;
	justify-content: center;
}

.room-container {
	display: grid;
	width: 100%;
	height: 100%;
	place-items: center;
	padding: 24px;
	border-radius: 16px;
	box-sizing: border-box;
}

.room-page {
	max-width: 70%;
	background: gray;
	font-size: 16px;
	padding: 32px;
	position: relative;
	flex-direction: column;
	display: flex;
	align-items: center;
	gap: 16px;
}

.randomText {
	color: white;
	text-align: center;
	width: 100%;
	font-size: 24px;
}

.waiting {
	height: 250px;
	width: 250px;
	border-radius: 16px;
	overflow: hidden;
}

.waiting > img {
	height: 100%;
	width: 100%;
}

@keyframes breathe {
	0% {
		box-shadow: 0px 0px 20px 0px rgba(83, 83, 255, 0);
		transform: scale(1) translate(-50%, -50%);
	}
	50% {
		box-shadow: 0px 0px 20px 0px rgba(83, 83, 255, 0.5);
		transform: scale(1.005) translate(-50%, -50%);
	}
	100% {
		box-shadow: 0px 0px 20px 0px rgba(83, 83, 255, 0);
		transform: scale(1) translate(-50%, -50%);
	}
}
